<div>
    <div class="container">
        <h1 class="text fw-bold mb-5 mt-5">¿Cómo podemos ayudarte?</h1>
        <div class="row">
            <div class="d-none d-md-block col-md-6">
                <small class="text fw-bold">Celular con WhatsApp</small>
                <p class="text h3 fw-lighter mb-3">(443)231-54-95</p>
                <p class="mb-5"><a href="https://wa.me/4432315495">
                    <div class="how-link">
                        <div class="how-link-text pt-2">
                            Contactar
                        </div>
                        <div class="how-link-arrow pt-2 pb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
                        </div>
                    </div>
                </a></p>
                <small class="text fw-bold mt-5">Correo electrónico</small>
                <p class="text h2 fw-lighter mb-5">ciro.globalsupplier@gmail.com</p>
                <img src="../../../assets/img/contact-image.png " alt="">
            </div>
            <div class="d-md-none col-12 text-center">
                <small class="text fw-bold">Celular con WhatsApp</small>
                <p class="text h3 fw-lighter mb-3">(443)231-54-95</p>
                <p class="mb-5"><a href="https://wa.me/4432315495">
                    <div class="how-link">
                        <div class="how-link-text pt-2">
                            Contactar
                        </div>
                        <div class="how-link-arrow pt-2 pb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
                        </div>
                    </div>
                </a></p>
                <small class="text fw-bold mt-5">Correo electrónico</small>
                <p class="text h2 fw-lighter mb-5">ciro.globalsupplier@gmail.com</p>
            </div>
            <div class="col-12 col-md-5">
                <small class="text fw-bold">Ó manda mensaje directo:</small>
                <form class="mb-5" [formGroup]="contactForm">
                    <div>
                        <input class="contact-input p-2 my-2" type="text" formControlName="name" placeholder="Nombre completo">
                    </div>
                    <div>
                        <input class="contact-input p-2 my-2" type="text" formControlName="email" placeholder="Celular de contacto">
                    </div>
                    <div>
                        <input class="contact-input p-2 my-2" type="text" formControlName="phone" placeholder="Correo electrónico">
                    </div>
                    <div>
                        <textarea class="contact-input p-2 my-2" name="message" formControlName="message" id="message" cols="30" rows="10" placeholder="Escribe tu mensaje"></textarea>
                    </div>
                    <div class="text-end">
                        <button class="send-button" (click)="sendMessage()">
                            <div class="how-link">
                                <div class="how-link-text pt-2">
                                    Enviar
                                </div>
                                <div class="how-link-arrow pt-2 pb-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
                <img src="../../../assets/img/contact-image-small.png " alt="" class="d-md-none" style="width: 100%;">
                <div class="d-none d-md-block">
                    <div class="contact-text p-5">
                        <p class="text">
                            La salud es un elemento invaluable y fundamental<br>
                            para que las personas tengan una vida de calidad.<br><br>
                            Por eso en Ciro Global Supplier tenemos el<br>
                            compromiso de proveer medicamentos y<br>
                            materiales especializados de manera segura,<br>
                            eficaz y accesible a quienes lo necesitan.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-md-none">
        <div class="contact-text-small p-4">
            <p class="text">
                La salud es un elemento invaluable y fundamental<br>
                para que las personas tengan una vida de calidad.<br><br>
                Por eso en Ciro Global Supplier tenemos el<br>
                compromiso de proveer medicamentos y<br>
                materiales especializados de manera segura,<br>
                eficaz y accesible a quienes lo necesitan.
            </p>
        </div>
    </div>
</div>
