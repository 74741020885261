<div class="section">
    <div class="">
        <div class="row">
            <div class="d-none d-md-block col-md-5 about-man-1"></div>
            <div class="col-12 d-md-none about-man-1-small"></div>
            <div class="col-12 col-md-7 pt-5 pb-5 aboutus-text-background">
                <br><br><br>
                <div class="row-section">
                    <div class="col-12 col-md-7 p-5">
                        <h2 class="text fw-bold">Quiénes somos</h2>
                        <p class="text">
                            Ciro Global Supplier, S.A. de C.V., es una empresa mexicana dedicada a la
                            comercialización y distribución de medicamentos de alta especialidad, productos y
                            terapias enfocados en la asistencia de heridas, accesorios oncológicos, suplementos
                            alimenticios e insumos para el cuidado y apoyo a personas de la tercera edad.
                        </p>
                    </div>
                </div>
                <br><br><br>
            </div>
        </div>
    </div>
    <div class="mission-section">
        <div class="d-none d-md-block col-md-6 cart-background text-center">
        </div>
        <div class="d-md-none col-12 cart-background-small text-center">
            <img src="../../../assets/img/hearth.svg" style="margin-top: 150px;" alt="">
        </div>
        <div class="col-12 col-md-6">
            <div class="blue-background p-5">
                <h5 class="text-white fw-lighter">
                    Nuestra misión es asegurar la entrega de nuestros productos y servicios de manera oportuna,
                    completa y a un precio competitivo.
                </h5>
            </div>
            <div class="orange-background p-5 fw-lighter">
                <br>
                <h5 class="text-white">
                    Principios de nuestra compañía
                </h5><br>
                <p class="text-white lh-lg">
                    <span class="gray-text fw-bold">Integridad:</span> Trabajando con honestidad, congruencia y transparencia, asegurando los
                    estándares más altos de ética y calidad. <br><br>
                    <span class="gray-text fw-bold">Ecuanimidad:</span> Buscando mantener la imparcialidad, justicia y objetividad a la hora
                    de actuar y tomar decisiones. <br><br>
                    <span class="gray-text fw-bold">Magnanimidad:</span> Operando con benevolencia, generosidad y amor.
                </p>
            </div>
        </div>
    </div>
    <div class="container p-5">
        <div class="row">
            <div class="col-12 col-md-6 about-man-2"></div>
            <div class="col-12 col-md-6">
                <h3 class="blue-text fw-bold">Nuestro compromiso</h3>
                <ul>
                    <li class="orange-text mt-3 fw-bold"><small class="blue-text">Entregas a domicilio, oficina, hospital, clínica o cualquier otro punto de encuentro.</small></li>
                    <li class="orange-text mt-3 fw-bold"><small class="blue-text">Horario de entregas 24/7.</small></li>
                    <li class="orange-text mt-3 fw-bold"><small class="blue-text">Envíos a toda la República.</small></li>
                    <li class="orange-text mt-3 fw-bold"><small class="blue-text">Manejamos medicamentos de Patente y Genéricos.</small></li>
                    <li class="orange-text mt-3 fw-bold"><small class="blue-text">Importación de medicamentos no disponibles en Mexico.</small></li>
                    <li class="orange-text mt-3 fw-bold"><small class="blue-text">Garantizamos tratamientos completos.</small></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="medicines">
        <div class="blue-tint">
            <p class="text-white mt-5">Ciro Siempre Contigo</p>
            <h2 class="text-white fw-bold mb-5">Atendemos desde alta especialidad hasta material de<br> curación, oncológico y suplementos alimenticios.</h2>
            <a href="/services">
                <div class="know-link">
                    <div class="know-link-text pt-2">
                        Conocer más
                    </div>
                    <div class="know-link-arrow pt-2 pb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
    
