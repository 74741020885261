<div class="section pb-5">
    <div class="row-section">
        <div class="d-none d-md-block col-2"></div>
        <div class="col-12 col-md-4 mt-5 pt-5 ps-5">
            <p class="title d-none d-md-block text">Alta Especialidad</p>
            <p class="title-small d-md-none text">Alta Especialidad</p>
            <div class="row-section pe-5">
                <div class="col-6">
                    <ul>
                        <li class="orange-text fw-bold"><small class="text">Cardiología</small></li>
                        <li class="orange-text fw-bold"><small class="text">Geriatría</small></li>
                        <li class="orange-text fw-bold"><small class="text">Hematología</small></li>
                        <li class="orange-text fw-bold"><small class="text">Infectología</small></li>
                        <li class="orange-text fw-bold"><small class="text">Medicina Interna</small></li>
                        <li class="orange-text fw-bold"><small class="text">Medicina Crítica</small></li>
                    </ul>
                </div>
                <div class="col-6">
                    <ul>
                        <li class="orange-text fw-bold"><small class="text">Medicina de Urgencias</small></li>
                        <li class="orange-text fw-bold"><small class="text">Neurología</small></li>
                        <li class="orange-text fw-bold"><small class="text">Nefrología</small></li>
                        <li class="orange-text fw-bold"><small class="text">Oncología</small></li>
                        <li class="orange-text fw-bold"><small class="text">Reumatología</small></li>
                    </ul>
                </div>
            </div>
            <p class="text-light-gray mt-5 mb-4">Algunos de nuestros laboratorios</p>
            <div class="row-section row-cols-5 row-cols-md-6 mb-5">
                <div class="col">
                    <img src="../../../assets/img/brands/brand-1.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-2.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-3.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-4.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-5.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-6.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-7.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-8.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-9.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-10.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-11.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-12.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-13.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-14.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-15.svg" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-16.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-17.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-18.png" alt="">
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 lines-bg-1">
        </div>
    </div>
    <div class="row-section">
        <div class="col-2"></div>
        <div class="col-12 col-md-6 mt-5 pt-5 ps-5">
            <p class="title text d-none d-md-block">Productos y Terapias enfocados<br>en la asistencia de heridas</p>
            <p class="title-small d-md-none text">Productos y Terapias enfocados en la asistencia de heridas</p>
            <div class="row-section">
                <div class="col-12 col-md-5 pe-5">
                    <ul>
                        <li class="orange-text fw-bold"><small class="text">Terapia de Cierre Asistido por Vacío</small><br>
                        <span class="text fw-light">Sistema de terapia de presión negativa para promover la cicatrización de heridas</span></li><br>
                        <li class="orange-text fw-bold"><small class="text">Systagenix</small><br>
                        <span class="text fw-light">Apósitos de alta tecnología</span></li><br>
                        <li class="orange-text fw-bold"><small class="text">Aldanex</small><br>
                        <span class="text fw-light">Crema protectora para problemas de piel relacionados con humedad</span></li><br>
                        <li class="orange-text fw-bold"><small class="text">Drawtex</small><br>
                        <span class="text fw-light">Apósitos para una preparación efectiva del lecho de herida</span></li><br>
                        <li class="orange-text fw-bold"><small class="text">Silverlon</small><br>
                        <span class="text fw-light">Favorese la liberación de iones de plata a planos profundos en el sitio de incisión</span></li>
                    </ul>
                </div>
                <div class="col-12 col-md-5 pe-5">
                    <ul>
                        <li class="orange-text fw-bold"><small class="text">Herniamesh</small><br>
                        <span class="text fw-light">Dispositivos para hernioplastía inguinal y abdominal, lesión de suelo pévico y decúbito</span></li><br>
                        <li class="orange-text fw-bold"><small class="text">Systagenix</small><br>
                        <span class="text fw-light">Matríz que favorece la neoformación de tejido</span></li><br>
                        <li class="orange-text fw-bold"><small class="text">Coloplast</small><br>
                        <span class="text fw-light">Dispositivos médicos relacionados con ostomía, urología, continencia y cuidado de heridas</span></li><br>
                        <li class="orange-text fw-bold"><small class="text">Paparch</small><br>
                        <span class="text fw-light">Paparch Parches de gel diseñado para manejar los retos post-operatorios</span></li><br>
                        <li class="orange-text fw-bold"><small class="text">Vashe</small><br>
                        <span class="text fw-light">Solución de heridas para preparación de lecho de una manera biocompatible, segura, efectiva y natural</span></li>
                    </ul>
                </div>
            </div>
            <p class="text-light-gray mt-5 mb-4">Nuestro proveedores</p>
            <div class="row-section row-cols-4 row-cols-md-5">
                <div class="col">
                    <img src="../../../assets/img/brands/brand-19.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-20.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-21.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-22.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-23.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-24.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-25.svg" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-26.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-27.png" alt="">
                </div>
                <div class="col">
                    <img src="../../../assets/img/brands/brand-28.png" alt="">
                </div>
            </div>
        </div>
        <div class="d-none d-md-block col-md-4 lines-bg-2">
        </div>
        <div class="col-12 d-md-none lines-bg-2-small">
        </div>
    </div>
    <div class="row-section">
        <div class="col-2"></div>
        <div class="col-12 col-md-3 mt-5 p-5">
            <p class="title d-none d-md-block text">Accesorios Oncológicos</p>
            <p class="title-small d-md-none text">Accesorios Oncológicos</p>
            <ul>
                <li class="orange-text fw-bold"><small class="text">Turbantes línea nacional</small><br>
                    <span class="text fw-light">Turbantes oncológicos nacional con 95% algodon y 5% de spandex</span></li><br>
                <li class="orange-text fw-bold"><small class="text">Turbantes Jon Renau</small><br>
                <span class="text fw-light">Confeccionados con un 95% de fibra de bambú y un 5% de elástico para su adecuada fijación.</span></li><br>
                <li class="orange-text fw-bold"><small class="text">Turbantes Christine Headwear</small><br>
                <span class="text fw-light">Fabricados con tejidos de máxima calidad, antialérgicos y de fibras naturales; seda, algodón, bambú, etc.</span></li><br>
                <li class="orange-text fw-bold"><small class="text">Cremas humectantes:<br>
                <span class="fw-light">Línea Lindiskin y Línea Miaderm.</span></small></li>
                <li class="orange-text fw-bold"><small class="text">Shampoo y acondicionador capilar: <span class="fw-light">Línea Alra.</span></small>
                <li class="orange-text fw-bold"><small class="text">Jabón Corporal y Facial: <span class="fw-light">Línea Miaderm.</span></small>
                <li class="orange-text fw-bold"><small class="text">Gel Corporal: <span class="fw-light">Línea VEA.</span></small>
                <li class="orange-text fw-bold"><small class="text">Spray Bucal: <span class="fw-light">Línea VEA.</span></small>
            </ul>
            <p class="text-light-gray mt-5 mb-4 fw-bolder">“Toda tu eres hermosa, amada mía, bella en todo sentido.”<br><span class="fw-light fst-italic">Cantares 4:7</span></p>
        </div>
        <div class="d-none d-md-block col-md-7 mt-5 lines-bg-3">
        </div>
        <div class="col-12 d-md-none mt-5 lines-bg-3-small">
        </div>
    </div>
    <p class="title d-none d-md-block text padding-left mt-5">Suplementos Alimenticios</p>
    <p class="title-small d-md-none text ps-5 mt-5">Suplementos Alimenticios</p>
    <div class="row-section p-5">
        <div class="d-none d-md-block col-md-2"></div>
        <div class="col-12 col-md-4 pe-5">
            <ul>
                <li class="orange-text fw-bold"><small class="text">GudGut</small><br>
                <span class="text fw-light">Probióticos: Estás bacterias no patógenas engloban el bienestar para restaurar, mantener y mejorar la salud intestinal. Regulan el sistema inmune, modulan el sistema nervioso por medio del eje microbiota-intestino-cerebro y mejoran el sistema gastrointestinal.<br><br>Contiene 35 billones de bacterias benéficas: Lactobacillus, Acidophilus, Lactobacillus Rhamnosus y Bifidobacterium Longum.<br><br>Citrato de magnesio + cúrcuma + piperina: Este producto no solo ayuda a regular tu tránsito intestinal sino que también en conjunto con la cúrcuma ayudará a disminuir la inflamación intestinal a nivel celular, incrementará la cantidad de probióticos en tu microbiota, etc.</span></li>
            </ul>
            <div class="lines-bg-5">
            </div>
        </div>
        <div class="col-12 col-md-4 pe-5">
            <ul>
                <li class="orange-text fw-bold"><small class="text">Ela Nutrition</small><br>
                <span class="text fw-light">El colageno es un nutriente esencial al ser la proteína más abundante en el cuerpo humano jugando un papel muy importante para mantenernos jóvenes y vitales. A partir de los 25 años comenzamos a perder la densidad y la producción natural de este en nuestro cuerpo.<br><br>Pensando en contrarrestar este desgaste y apoyando tu belleza y bienestar desde adentro, nace el acercar los diferentes productos de Ela Nutrition en sus distintas presentaciones.<br>-Beauty collagen lemon ginger.<br>-Beauty collagen natural berries + C.<br>-Beauty collagen matcha lemonade.<br>-Marine collagen.<br>-Collagen peptides grass fed + C + D + Zinc.</span></li>
            </ul>
            <div class="d-none d-md-block lines-bg-4">
            </div>
            <div class="d-md-none lines-bg-4-small">
            </div>
        </div>
    </div>
    
</div>