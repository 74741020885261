import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required)
  });

  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
  }

  sendMessage(){
    if (this.contactForm.valid)
      this.apiService.request('POST', 'mail/message', {
        name: this.contactForm.value.name,
        email: this.contactForm.value.email,
        phone: this.contactForm.value.phone,
        message: this.contactForm.value.message
      }).subscribe(
        response => {
          alert('Su mensaje ha sido enviado');
          this.router.navigate(['/']);
        },
        error => {
          alert('Ocurrió un error interno, intente de nuevo más tarde');
          console.log(error);
        }
      );
  }

}
