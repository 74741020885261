<div class="row-section">
    <div class="d-none d-md-block col-md-1"></div>
    <div class="col-12 col-md-10 blue-background"><br><br></div>
    <div class="d-none d-md-block col-md-1"></div>
</div>
<div class="row-section">
    <div class="d-none d-md-block col-md-1"></div>
    <div class="col-12 col-md-11 blue-background">
        <div class="row-section">
            <div class="col-12 col-md-6">
                <div class="row-section">
                    <div class="col-6 col-md-4 px-5">
                        <p class="text-white"><strong>Navegación</strong></p>
                        <p class="footer-link"><a class="text-white" href="/">Inicio</a></p>
                        <p class="footer-link"><a class="text-white" href="/services">Líneas de Negocio</a></p>
                    </div>
                    <div class="col-6 col-md-4 px-5">
                        <p>&nbsp;</p>
                        <p class="footer-link"><a class="text-white" href="/about-us">Nosotros</a></p>
                        <p class="footer-link"><a class="text-white" href="/contact">Contacto</a></p>
                    </div>
                    <div class="col-6 col-md-4 px-5">
                        <p class="text-white"><strong>Redes Sociales</strong></p>
                        <div class="row">
                            <div class="col-4">
                                <a href="https://www.facebook.com/ciroglobal" target="new">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.725" height="28" viewBox="0 0 14.725 28"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-551.596 -2974.117)"><path class="a" d="M562.64,2974.117c.749,0,1.5.04,2.249.074.487.02.974.089,1.432.139l-.035,4.508h-.358c-.495,0-.989-.006-1.484-.006-.371,0-.742,0-1.114.016-1.194.04-2.073.488-2.134,1.981-.039,1.075-.066,3.448-.068,3.573.063,0,1.243,0,2.432,0,.594,0,1.175,0,1.579,0h.966c-.243,1.731-.464,3.393-.706,5.065H561.13l-.1,12.65h-5.245l.1-12.67H551.6l.038-5.035h4.309l0-.379c.008-1.015,0-2.03.033-3.045a12.714,12.714,0,0,1,.164-1.931,5.736,5.736,0,0,1,2.3-3.762,6.6,6.6,0,0,1,3.781-1.174C562.361,2974.118,562.5,2974.117,562.64,2974.117Z"/></g></svg>
                                </a>
                            </div>
                            <div class="col-4">
                                <a href="https://www.instagram.com/ciroglobal" target="new">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.79" height="24.779" viewBox="0 0 24.79 24.779"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-597.955 -2975.727)"><path class="a" d="M617.006,2980.037h.018a1.475,1.475,0,0,1,1.472,1.493,1.5,1.5,0,0,1-1.5,1.482,1.487,1.487,0,0,1,.015-2.975Z"/><path class="a" d="M622.593,2981.947c.184,1.951.3,11.167-.271,13.326a6.342,6.342,0,0,1-5.472,4.957,61.2,61.2,0,0,1-6.837.276,42.033,42.033,0,0,1-6.886-.366,6.216,6.216,0,0,1-4.935-5.4c-.332-2.09-.368-11.8.235-13.913a6.322,6.322,0,0,1,5.4-4.817,48.37,48.37,0,0,1,6.211-.279c2.313,0,4.647.063,6.052.14a6.828,6.828,0,0,1,4.524,1.851A6.524,6.524,0,0,1,622.593,2981.947Zm-2.384,12.381a88.892,88.892,0,0,0,.153-12.172,4.169,4.169,0,0,0-3.712-3.951,56.953,56.953,0,0,0-6.2-.233c-2.467,0-4.923.066-6,.184a4.246,4.246,0,0,0-3.96,3.732,84.547,84.547,0,0,0-.085,12.51,4.019,4.019,0,0,0,3.058,3.5,38.425,38.425,0,0,0,6.666.366c2.6,0,5.152-.082,6.112-.187a4.231,4.231,0,0,0,3.969-3.752"/><path class="a" d="M610.4,2981.768a6.3,6.3,0,0,1,6.3,6.36,6.415,6.415,0,0,1-6.408,6.349,6.291,6.291,0,0,1-6.3-6.359A6.4,6.4,0,0,1,610.4,2981.768Zm-.093,10.46a4.15,4.15,0,0,0,4.144-4.07,4.068,4.068,0,0,0-4.039-4.15h-.036a4.15,4.15,0,0,0-4.145,4.071,4.075,4.075,0,0,0,4.039,4.15h.037"/></g></svg>
                                </a>
                            </div>
                            <div class="col-4">
                                <a href="https://wa.me/4432315495" target="new">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23.973" height="24" viewBox="0 0 23.973 24"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-1088.315 -62.034)"><path class="a" d="M1096.354,68.443a4.1,4.1,0,0,1,.687.08c.136.018.261.217.332.362.328.768.657,1.536.94,2.322.159.415-.133.813-.743,1.5a.524.524,0,0,0-.087.677,8.043,8.043,0,0,0,4.111,3.551.636.636,0,0,0,.225.046.511.511,0,0,0,.4-.217c.766-.926.985-1.331,1.3-1.331a.6.6,0,0,1,.26.075c2.394,1.147,2.584,1.192,2.591,1.463a2.621,2.621,0,0,1-2.749,2.681c-.059,0-.116,0-.172-.006-2.322-.082-6.012-1.916-8.517-6.325a3.586,3.586,0,0,1,.586-4.572A1.166,1.166,0,0,1,1096.354,68.443Z"/><path class="a" d="M1112.106,71.731a12.033,12.033,0,0,1-9.738,13.959,12.272,12.272,0,0,1-2.085.18,12.033,12.033,0,0,1-5.334-1.264,1.138,1.138,0,0,0-.493-.108.991.991,0,0,0-.238.027c-3.16.795-5.26,1.382-5.9,1.509.14-.515.26-.967.381-1.419.409-1.445.827-2.882,1.208-4.328a1.075,1.075,0,0,0-.058-.695,11.976,11.976,0,0,1,7.929-17.231,12.284,12.284,0,0,1,2.8-.327A11.754,11.754,0,0,1,1112.106,71.731Zm-10.23,12.044a10.035,10.035,0,0,0,8.363-11.3,9.847,9.847,0,0,0-9.743-8.478,10.121,10.121,0,0,0-1.813.165,10,10,0,0,0-7.024,14.592,2.042,2.042,0,0,1,.2,1.889c-.3.84-.483,1.707-.752,2.656,1.186-.307,2.281-.578,3.368-.885a1.091,1.091,0,0,1,.284-.04,1.039,1.039,0,0,1,.528.157,9.768,9.768,0,0,0,4.965,1.379,10.111,10.111,0,0,0,1.621-.132"/></g></svg>
                                </a>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
                <div class="d-md-none col-12 orange-background-small p-5 mt-2">
                    <p class="text-white"><strong>Oficinas</strong></p>
                    <p class="text-white h4 fw-light">
                        Avenida Libertad #171<br>
                        Colonia Lomas de Hidalgo<br>
                        C.P. 58240
                    </p>
                </div>
                <div class="px-5">
                    <br><br>
                    <p class="text-white"><strong>2021 &reg; Ciro Global Supplier .</strong> WABI Solutions/JOY</p>
                </div>
            </div>
            <div class="d-none d-md-block col-md-6 orange-background p-5">
                <p class="text-white"><strong>Oficinas</strong></p>
                <p class="text-white h4 fw-light">
                    Avenida Libertad #171<br>
                    Colonia Lomas de Hidalgo<br>
                    C.P. 58240
                </p>
            </div>
        </div>
    </div>
</div>