<div class="container pb-5 pt-5">
  <div class="row">
    <div class="d-none d-md-block col-md-6">
      <a href="/">
        <img src="assets/img/logo.svg" alt="Ciro Logo">
      </a>
    </div>
    <div class="col-md-6 d-none d-md-block">
      <div class="row">
        <div class="col-3"><a [ngClass]="{'active-link':(router.url == '/about-us'),'navbar-link':(router.url !== '/about-us')}" href="/about-us">Nosotros</a></div>
        <div class="col-4"><a [ngClass]="{'active-link':(router.url == '/services'),'navbar-link':(router.url !== '/services')}" href="/services">Líneas de Negocio</a></div>
        <div class="col-3"><a [ngClass]="{'active-link':(router.url == '/contact'),'navbar-link':(router.url !== '/contact')}" href="/contact">Contacto</a></div>
        <div class="col-2"><a class="navbar-link" href="https://wa.me/4432315495"><img src="assets/img/whatsapp-logo.svg" alt="WhatsApp Logo"></a></div>
      </div>
    </div>
    <div class="d-md-none col-md-12 text-center">
      <a href="/">
        <img src="assets/img/logo.svg" alt="Ciro Logo">
      </a>
    </div>
    <div class="col-6 d-md-none">
      <button type="button" class="btn btn-white" data-bs-toggle="modal" data-bs-target="#modal">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#8C969B" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </button>
    </div>
    <div class="col-6 d-md-none text-end">
      <a class="navbar-link" href="https://wa.me/4432315495"><img src="assets/img/whatsapp-logo.svg" alt="WhatsApp Logo"></a>
    </div>
  </div>
</div>

<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="text-center">
        <img src="../../../assets/img/logo-white.png" class="modal-logo mt-4" alt="">
      </div>
      <svg data-bs-dismiss="modal" aria-label="Close" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#FFFFFF" class="bi bi-x-lg m-3" viewBox="0 0 16 16">
        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
      </svg>
      <div class="m-5 py-5">
        <p><a style="text-decoration: none;" class="text-white fw-bold h1" href="/about-us">Nosotros</a></p><br>
        <p><a style="text-decoration: none;" class="text-white fw-bold h1" href="/services">Líneas de Negocio</a></p><br>
        <p><a style="text-decoration: none;" class="text-white fw-bold h1" href="/contact">Contacto</a></p><br>
        <p><a style="text-decoration: none;" class="text-white fw-bold h1" href="https://wa.me/4432315495"><img src="assets/img/whatsapp-logo-white.svg" alt="WhatsApp Logo"></a></p>
      </div>
      <img src="../../../assets/img/modal-image.png" alt="circles" style="width:100px;">
    </div>
  </div>
</div>

