import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {

    private apiURL = 'https://api.ciroglobal.com';

    constructor(private http: HttpClient) {}

    public request(method: string, endpoint: string, body?: any): Observable<any> {
        let headers: HttpHeaders = new HttpHeaders();
        return this.http.request(method, `${this.apiURL}/${endpoint}`, {
            body,
            headers,
        });
    }
}