<div class="section">
    <div class="container pt-5">
        <div class="row-section pb-5">
            <div class="col-12 d-md-none hands-background-small">
            </div>
            <div class="col-12 col-md-5 pt-5">
                <div class="light-blue-circle-div">
                    <br>
                </div>
                <div class="home-introduction p-2">
                    <p class="title">El arte de ayudar oportunamente.</p>
                    <p class="subtitle">
                        Proveemos medicamentos de alta especialidad, productos y terapias enfocados 
                        en la asistencia de heridas, accesorios oncológicos e insumos para el cuidado 
                        y apoyo a personas de la tercera edad.
                    </p>
                    <a href="about-us">
                        <div class="how-link">
                            <div class="how-link-text pt-2">
                                Cómo lo hacemos
                            </div>
                            <div class="how-link-arrow pt-2 pb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="d-none d-md-block col-md-1"></div>
            <div class=" d-none d-md-block col-md-6 hands-background">
            </div>
        </div>
    </div>
    <div class="d-none d-md-flex row-section pt-5 pb-5 drug-cart">
        <div class="col-md-5"></div>
        <div class="col-md-7 text-middle pt-5 pb-5">
            <p>Nuestro objetivo es <strong>que los pacientes siempre<br>
            puedan contar con su medicamento de<br>
            manera oportuna</strong>, ya que esto beneficia en su<br>
            recuperación y calidad de vida.</p>
        </div>
    </div>
    <div class="d-md-none row-section pt-5">
        <div class="col-12 p-5 drug-cart-small"></div>
        <div class="col-12 text-middle-small p-5 text-center" style="background-color: #98C8F4">
            <p>Nuestro objetivo es <strong>que los pacientes siempre
            puedan contar con su medicamento de
            manera oportuna</strong>, ya que esto beneficia en su<br>
            recuperación y calidad de vida.</p>
        </div>
    </div>
    <div class="blue-background">
        <div class="pt-5 images-title">
            <p class="text-center special-text">¿Y qué más nos hace especiales?</p>
            <div class="row-section">
                <div class="d-none d-md-block col-md-2 images-background-left"></div>
                <div class="col-12 col-md-4 home-images pb-5">
                    <img class="mt-5" src="../../../assets/img/rapidez.png" alt="">
                    <h3 class="text-white text-center">Rapidez</h3>
                    <p class="text-white">Entregas a domicilio las 24 horas,<br> los 7 días de la semana.</p>
                    <img class="mt-5" src="../../../assets/img/diversidad.png" alt="">
                    <h3 class="text-white text-center">Diversidad</h3>
                    <p class="text-white">Manejamos medicamentos de<br> patente y genéricos.</p>
                </div>
                <div class="col-12 col-md-4 home-images pb-5">
                    <img class="mt-5" src="../../../assets/img/cobertura.png" alt="">
                    <h3 class="text-white text-center">Cobertura</h3>
                    <p class="text-white">Envíos a toda la república mexicana.</p>
                    <img class="mt-5" src="../../../assets/img/disponibilidad.png" alt="">
                    <h3 class="text-white text-center">Disponibilidad</h3>
                    <p class="text-white">Importación de medicamentos no<br> disponibles en México.</p>
                </div>
                <div class="d-none d-md-block col-md-2 images-background-right"></div>
            </div>
        </div>
    </div>
</div>
<div class="d-none d-md-block section home-image-background">
    <div class="container">
        <div class="row">
            <div class="col-1"></div>
            <div class="col-4 orange-background mx-auto pt-5 pb-5">
                <div class="container">
                    <img src="../../../assets/img/logo-white.svg" class="mb-3" alt="">
                    <h1 class="text-white">Éticamente responsables</h1>
                    <p class="text-white">Brindamos apoyo con precios a pacientes del sector público.</p>
                    <a href="contact">
                        <div class="contact-link">
                            <div class="contact-link-text pt-2">
                                Contáctanos
                            </div>
                            <div class="contact-link-arrow pt-2 pb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-7"></div>
        </div>
    </div>
</div>
<div class="d-md-none section">
    <div class="home-image-background-small text-center">
        <img src="../../../assets/img/hearth.svg" style="margin-top: 150px;" alt="">
    </div>
    <div class="orange-background mx-auto pt-5 pb-5">
        <div class="container">
            <img src="../../../assets/img/logo-white.svg" class="mb-3" alt="">
            <h1 class="text-white" style="font-size: 40px">Éticamente responsables</h1>
            <p class="text-white" style="font-size: 16px">Brindamos apoyo con precios a pacientes del sector público.</p>
            <div class="text-center">
                <a href="contact">
                    <div class="contact-link">
                        <div class="contact-link-text pt-2">
                            Contáctanos
                        </div>
                        <div class="contact-link-arrow pt-2 pb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="section ">
    <div class="container">
        <div class="row-section pt-5 pb-5">
            <div class="col-9 mx-auto text-center text-middle mt-5">
                <strong>ISAÍAS 61:1</strong><br>
                El Espíritu del Señor Soberano está sobre mí, porque el Señor me ha ungido para
                llevar buenas noticias a los pobres.<br>Me ha enviado para consolar a los de corazón
                quebrantado y a proclamar que los cautivos serán liberados y que los prisioneros
                serán puestos en libertad.
            </div>
        </div>
    </div>
</div>